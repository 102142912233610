import React, { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Heading } from '@wix/design-system';
import { useProfileContext } from '../../Contexts/ProfileContext';
import ShowMoreText from '../../ShowMoreText';

type Props = {
  isMobileView?: boolean;
};

const About: FC<Props> = ({ isMobileView }) => {
  const MAX_CHARS_IN_ABOUT = 200;
  const {
    partnerProfile: { professionalInformation },
  } = useProfileContext();
  const { t } = useTranslation();
  const about = professionalInformation?.about;
  const businessName = professionalInformation?.businessName;

  return (
    <Box
      direction="vertical"
      paddingTop="36px"
      dataHook="about-section"
      maxWidth={isMobileView ? '80vw' : '40vw'}
      width="100%"
    >
      <Box direction="vertical">
        <Heading
          size={isMobileView ? 'medium' : 'large'}
          as="h1"
          dataHook="about-header"
        >
          {t('profile-page-about-section-title', { businessName })}
        </Heading>
        <Box minHeight="18px" />
        <ShowMoreText originalText={about} maxChars={MAX_CHARS_IN_ABOUT} />
      </Box>
    </Box>
  );
};

export default About;
