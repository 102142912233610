import React, { useCallback, useMemo } from 'react';
import { useTranslation, useExperiments } from '@wix/fe-essentials-standalone';
import {
  Box,
  Text,
  MediaOverlay,
  Proportion,
  Tooltip,
  Image,
} from '@wix/design-system';
import { InfoCircle, ExternalLink } from '@wix/wix-ui-icons-common';
import { PortfolioItem } from '@wix/ambassador-partner-profiles/types';
import { copyOfExploreResultsPageGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useBi } from '@components/Hooks/useBi';
import { fillProfileUrl } from '@utils/urlUtils';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import {
  KeyCodeEnter,
  ENABLE_DISPLAY_STUDIO_BADGE_ON_PORTFOLIO_ITEMS_SPEC,
} from '@utils/constants';
import StudioBadgeSvg from '@components/Images/StudioBadgeSvg';
import s from './Project.module.scss';

export interface PortfolioItemWithUrl extends PortfolioItem {
  url?: string;
  isStudio?: boolean;
  isMobileView?: boolean;
}

interface InfoToolTipProps {
  title: string;
  description: string;
}

interface InfoToolTipContentProps {
  title: string;
  description: string;
}

const InfoToolTipContents = ({
  title,
  description,
}: InfoToolTipContentProps) => {
  return (
    <Box direction="vertical">
      <Box paddingBottom={description.length > 0 ? '6px' : '0px'}>
        <Text
          size="small"
          weight="bold"
          light
          dataHook="project-tool-tip-title"
        >
          {title}
        </Text>
      </Box>
      <Text
        size="small"
        light
        dataHook="project-tool-tip-description"
        textAlign="center"
      >
        {description}
      </Text>
    </Box>
  );
};

const InfoToolTip = ({ title, description }: InfoToolTipProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      content={<InfoToolTipContents title={title} description={description} />}
      appendTo="window"
      placement="bottom"
      dataHook="project-tool-tip"
      maxWidth={description && description.length > 240 ? '320px' : '200px'}
    >
      <Box verticalAlign="middle">
        <Box marginRight="11px">
          <Text light size="small" tagName="div">
            {t('profile-page-featured-projects-project-info')}
          </Text>
        </Box>
        <InfoCircle style={{ color: '#F8F8F8' }} />
      </Box>
    </Tooltip>
  );
};

export const generateThumbnailUrl = (metaSiteId: string) =>
  `https://www.wix.com/site-thumbnail/${metaSiteId}?w=450&h=300`;

const Project = ({
  customImage,
  description,
  metaSiteId,
  siteName,
  url,
  isStudio,
  isMobileView,
}: PortfolioItemWithUrl) => {
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { entry, origin } = useProfileContext();
  const { experiments } = useExperiments({ readOnly: true });
  const isEnableDisplayStudioBadgeOnPortfolioItems = experiments.enabled(
    ENABLE_DISPLAY_STUDIO_BADGE_ON_PORTFOLIO_ITEMS_SPEC,
  );

  const mediaSources = useMemo(() => {
    const generatedThumbnailSrc = metaSiteId
      ? generateThumbnailUrl(metaSiteId)
      : '';
    const mediaSource = customImage
      ? fillProfileUrl(customImage, 450, 300)
      : generatedThumbnailSrc;

    const backupSource = customImage ? generatedThumbnailSrc : '';
    return { mediaSource, backupSource };
  }, [metaSiteId, customImage]);

  const onClickLink = useCallback(() => {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType: 'project website',
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
        data: JSON.stringify({ metaSiteId }),
      }),
    );
  }, [sessionId, origin, entry, metaSiteId]);

  return (
    <div
      data-hook="portfolio-project"
      className={isMobileView ? s.projectMobile : s.project}
      role="link"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.keyCode === KeyCodeEnter && url) {
          onClickLink();
          window.open(url, '_blank');
        }
      }}
    >
      <Proportion aspectRatio={3 / 2} className={s.proportion}>
        <MediaOverlay
          hoverSkin="dark"
          borderRadius="2px"
          media={
            <Box display="block" width="100%" height="100%" position="relative">
              <Image
                dataHook="project-media-image"
                alt={`${siteName}: ${description}`}
                height="100%"
                showBorder
                borderRadius="2px"
                src={mediaSources.mediaSource}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  if (mediaSources.backupSource) {
                    e.currentTarget.src = mediaSources.backupSource;
                  }
                }}
              />
              {isEnableDisplayStudioBadgeOnPortfolioItems && isStudio ? (
                <Box
                  dataHook="project-studio-badge"
                  position="absolute"
                  right="0"
                  bottom="0"
                >
                  <StudioBadgeSvg />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          }
          dataHook="project-media-overlay"
        >
          <MediaOverlay.Content placement="top-end" visible="hover">
            <InfoToolTip
              title={siteName ?? ''}
              description={description ?? ''}
            />
          </MediaOverlay.Content>
          <MediaOverlay.Content placement="bottom-start" visible="hover">
            {url ? (
              <a
                href={url}
                className={s.viewWebsiteLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClickLink}
              >
                <Box verticalAlign="middle">
                  <ExternalLink
                    style={{ color: '#F8F8F8', marginRight: '10px' }}
                  />
                  <Text light size="small">
                    {t('profile-page-featured-projects-view-website')}
                  </Text>
                </Box>
              </a>
            ) : null}
          </MediaOverlay.Content>
        </MediaOverlay>
      </Proportion>
    </div>
  );
};

export default Project;
